import keyBy from "lodash/keyBy";

import axios from "../axiosClient.js";

/* @ngInject */
export default class WorkflowsService {
  constructor(backendConfig, $http, Authentication) {
    this._backendConfig = backendConfig;
    this._$http = $http;
    this._Authentication = Authentication;
    this._features = this._backendConfig.features;
    this.workflowSettings = {};
  }

  async getWorkflowSettings() {
    const {data: workflowsResponse} = await axios({
      method: "get",
      url: "/facilities/workflowSettings",
    });

    this.workflowSettings = keyBy(workflowsResponse, "facilityId");

    return this.workflowSettings;
  }
}
