/* eslint-env browser */

/* @ngInject */
export default class StudyAssignmentService {
  constructor(backendConfig, $http, Authentication) {
    this._backendConfig = backendConfig;
    this._$http = $http;
    this._Authentication = Authentication;
  }

  assignStudyToUsers(studyId, userIds, facilityId) {
    if (!userIds || userIds?.length === 0) {
      return Promise.resolve();
    }

    const url = `/studyAssignments/${studyId}`;
    const body = {
      facilityId,
      studyId,
      users: userIds.map(this._convertUserIdToObject),
    };
    return this._httpPost(url, body);
  }

  clearAssignments(studyId, userIds, facilityId) {
    if (!userIds || userIds?.length === 0) {
      return Promise.resolve();
    }

    const url = `/studyAssignments/${studyId}`;
    const body = {
      facilityId,
      users: userIds,
    };
    return this._httpDelete(url, body);
  }

  async getAssignedUsers(studyId) {
    const url = `/studyAssignments/${studyId}`;

    let assignedUsers;
    try {
      const response = await this._httpGet(url);
      assignedUsers = response.data.users;
    } catch (error) {
      if (error.status === 404 && error.data.title === "No study assignments found") {
        assignedUsers = [];
      } else {
        throw error;
      }
    }
    return assignedUsers;
  }

  _convertUserIdToObject(userId) {
    return {userId};
  }

  _httpPost(url, body) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.post(`${baseUrl}${url}`, body, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  _httpGet(url, params) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
      params,
    });
  }

  _httpDelete(url, body) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http({
      method: "DELETE",
      url: `${baseUrl}${url}`,
      headers: {
        Authorization: authHeader,
      },
      data: body,
    });
  }
}
