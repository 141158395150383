import React from "react";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Checkbox from "@mui/material/Checkbox";

function CheckboxInput({
  // Props
  name,
  control,
  defaultValue,
  "data-cy": dataCy,
  disabled = false,
  required = false,
  otherProps,
}) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister
      render={({field: {onChange, value}}) => (
        <Checkbox
          checked={value}
          onChange={onChange}
          disabled={disabled}
          required={required}
          data-cy={dataCy}
          color="secondary"
          {...otherProps}
        />
      )}
    />
  );
}

CheckboxInput.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  defaultValue: PropTypes.bool,
  "data-cy": PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  otherProps: PropTypes.object,
};

export default CheckboxInput;
