/* eslint-env browser */
import cloneDeep from "lodash/cloneDeep";
import forOwn from "lodash/forOwn";
import isEqual from "lodash/isEqual";
import moment from "moment";
import queryString from "qs";

/* @ngInject */
export default class StripService {
  constructor($injector) {
    this._$injector = $injector;
    this._backendConfig = $injector.get("backendConfig");
    this._$http = $injector.get("$http");
    this._Authentication = $injector.get("Authentication");
    this._EventService = $injector.get("EventService");
    this._Markers = $injector.get("Markers");
    this.features = this._backendConfig.features;
  }

  /**
   * @param {Object} params - properties to limit getStrips request by
   * @returns {Promise<Object[]>} strips
   *
   * @see SRS: BR-2455
   */
  async getStrips(params = {}) {
    const url = "/strips";
    const strips = (await this._httpGet(url, params)).data;

    strips.forEach((strip) => {
      // Valid timestamps are 2010 or later
      strip.validStartTime = moment(strip.startTime).year() >= 2010;
    });

    return strips;
  }

  /**
   * @param {Object} strip - properties to create strip
   * @returns {Promise} strip post request
   *
   * @see SRS: BR-72
   */
  createStrip(strip) {
    const url = "/strips";
    return this._httpPost(url, strip);
  }

  updateStrip(stripId, updatedProperties) {
    const url = `/strips/${stripId}`;
    return this._httpPatch(url, updatedProperties);
  }

  updateStrips(updatedProperties) {
    const url = "/strips";
    return this._httpPatch(url, updatedProperties);
  }

  getStripEvent(strip) {
    const eventType = this._EventService.getEcgEventType(strip.deviceClassification);
    return this._EventService.getEventAndEcg(eventType, strip.eventId).then((event) => {
      event.ecg.centeredSample = this.getStripMidpoint(strip, event.ecg);
      event.ecg.mmPerMillivolt = strip.gain;
      event.ecg.mmPerSecond = strip.timeBase;
      strip.event = event;

      return strip.event;
    });
  }

  updateStripMidpoint(strip) {
    if (strip.event) {
      strip.event.ecg.centeredSample = this.getStripMidpoint(strip, strip.event.ecg);
      strip.event.ecg.mmPerMillivolt = strip.gain;
      strip.event.ecg.mmPerSecond = strip.timeBase;
    }
    return strip;
  }

  orderStripList(strips, orderBy) {
    strips.sort((a, b) => a[orderBy] - b[orderBy]);
  }

  getStripMidpoint(strip, ecg) {
    const samplesPerSecond = 1000000 / ecg.samplePeriod;
    const samplesPerMillisecond = samplesPerSecond / 1000;
    const stripStart = moment(strip.startTime).valueOf();
    const stripEnd = moment(strip.endTime).valueOf();
    const ecgStart = moment(ecg.startTime).valueOf();
    const startStripSample = samplesPerMillisecond * (stripStart - ecgStart);
    const endStripSample = samplesPerMillisecond * (stripEnd - ecgStart);
    const stripMidpoint = (startStripSample + endStripSample) / 2;

    return stripMidpoint;
  }

  /**
   * Destructively sanitize measurements on the strip
   * @param {Object} strip
   * @return {Object} strip with sanitized measurements
   */
  sanitizeMeasurementsOnStrip(strip) {
    forOwn(strip.measurements, (type) => {
      if (Array.isArray(type.data)) {
        type.data.forEach((measurement) => {
          this._Markers.sanitizeStripMeasurement(measurement);
          measurement.stripId = strip.id;
        });
      }
    });
    return strip;
  }

  /**
   * Destructively sanitize a strip for the backend
   * @param {Object} strip
   * @return {Object} sanitized strip
   */
  sanitizeStrip(strip) {
    strip.startTime = moment(strip.startTime).toISOString();
    strip.endTime = moment(strip.endTime).toISOString();
    strip.comment = strip.comment || "";
    strip.gain = Number(strip.gain);
    strip.timeBase = Number(strip.timeBase);
    if (strip.displayedLeads === null) {
      strip.displayedLeads = {
        I: true,
        II: true,
        III: true,
      };
    }
    if (strip.invertedChannels === null) {
      strip.invertedChannels = {
        I: false,
        II: false,
        III: false,
      };
    }
    delete strip.order;
    delete strip.middleSample;
    delete strip.isSelected;
    delete strip.middleX;
    delete strip.secondsWide;
    delete strip.measurements;
    delete strip.singleEpisodeReport;
    delete strip.validStartTime;
    delete strip.meanHR;
    delete strip.displayedMeanHR;
    delete strip.heartRateTooltip;
    delete strip.displayedStartTime;
    delete strip.displaySER;
    delete strip.showMinChip;
    delete strip.showMaxChip;
    delete strip.isPatientActivated;
    return strip;
  }

  getMeasurementCount(measurementType, strip) {
    let count = 0;
    if (strip?.measurements) {
      // HR uses the data array from RR
      if (measurementType === "HR") {
        count = strip.measurements.RR.data.length;
      } else {
        count = strip.measurements[measurementType].data.length;
      }
    }
    return count;
  }

  getMeasurementMinMeanMax(measurementType, strip) {
    let minMeanMaxString = "--";
    let measurement = {};
    let measurementCount = 0;

    if (strip?.measurements) {
      measurement = strip.measurements[measurementType];
      // HR uses the data array from RR
      if (measurementType === "HR") {
        measurementCount = strip.measurements.RR.data.length;
      } else {
        measurementCount = measurement.data.length;
      }
    }

    switch (measurementCount) {
      case 0:
        minMeanMaxString = "--";
        break;
      case 1:
        minMeanMaxString = `${measurement.min}`;
        break;
      default:
        minMeanMaxString = `${measurement.min} - ${measurement.mean} - ${measurement.max}`;
    }
    return minMeanMaxString;
  }

  stripsAreEqual(stripA = {}, stripB = {}) {
    const checkStripA = {
      deviceClassification: stripA.deviceClassification || "",
      userClassification: stripA.userClassification || "",
      eventId: stripA.eventId || "",
      studyId: Number(stripA.studyId),
      enrollmentId: stripA.enrollmentId || "",
      facilityId: stripA.facilityId || "",
      displayedLeads: cloneDeep(stripA.displayedLeads),
      invertedChannels: cloneDeep(stripA.invertedChannels),
      timeBase: Number(stripA.timeBase),
      gain: Number(stripA.gain),
      startTime: moment(stripA.startTime).toISOString(),
      endTime: moment(stripA.endTime).toISOString(),
      comment: stripA.comment || "",
      createdBy: stripA.createdBy || "",
    };
    const checkStripB = {
      deviceClassification: stripB.deviceClassification || "",
      userClassification: stripB.userClassification || "",
      eventId: stripB.eventId || "",
      studyId: Number(stripB.studyId),
      enrollmentId: stripB.enrollmentId || "",
      facilityId: stripB.facilityId || "",
      displayedLeads: cloneDeep(stripB.displayedLeads),
      invertedChannels: cloneDeep(stripB.invertedChannels),
      timeBase: Number(stripB.timeBase),
      gain: Number(stripB.gain),
      startTime: moment(stripB.startTime).toISOString(),
      endTime: moment(stripB.endTime).toISOString(),
      comment: stripB.comment || "",
      createdBy: stripB.createdBy || "",
    };

    return isEqual(checkStripA, checkStripB);
  }

  /**
   * @param {string} url   - the url to make a request to
   * @param {Object} params - the query parameters to attach to the request
   * @returns {Promise} get request
   * @see SRS: BR-2455
   */
  _httpGet(url, params) {
    const urlQuery = queryString.stringify(params);
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}?${urlQuery}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  /**
   * @param {string} url   - the url to make a request to
   * @param {Object} strip - the strip object to attach to the request
   * @returns {Promise} post request
   * @see SRS: BR-15, BR-2448
   */
  _httpPost(url, strip) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.post(`${baseUrl}${url}`, strip, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  _httpPatch(url, data) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.patch(`${baseUrl}${url}`, data, {
      headers: {
        Authorization: authHeader,
      },
    });
  }
}
