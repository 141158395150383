import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import styled from "@mui/system/styled";

const ClampedTextContent = styled("div")({
  display: "-webkit-box",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitBoxOrient: "vertical",
});

function ShowMoreText({id, children}) {
  const [hidden, setHidden] = React.useState(true);
  const [overflow, setOverflow] = React.useState(false);
  const textRef = React.createRef();

  React.useLayoutEffect(() => {
    const text = textRef.current;

    if (text && text.offsetHeight < text.scrollHeight) {
      setOverflow(true);
    }
  }, [textRef]);

  const handleClick = React.useCallback(() => setHidden((prev) => !prev), []);

  return (
    <>
      {hidden ? <ClampedTextContent ref={textRef}>{children}</ClampedTextContent> : <div>{children}</div>}

      {overflow && (
        <Box sx={{display: "flex", justifyContent: "center"}}>
          <Button id={id} size="small" color="secondary" onClick={handleClick}>
            {hidden ? "Show More" : "Show Less"}
          </Button>
        </Box>
      )}
    </>
  );
}

ShowMoreText.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired,
};
export default ShowMoreText;
