/* eslint-env browser */
import angular from "angular";

/* @ngInject */
export default class ScrollService {
  constructor($rootScope, Config) {
    this._$rootScope = $rootScope;
    this.features = Config.features;
  }

  getElementTop(id) {
    return document.getElementById(id).getBoundingClientRect().top;
  }

  // behavior: ["smooth", "instant", "auto"]
  scrollByDistance(top, behavior = "smooth") {
    angular.element(() => {
      window.scrollBy({top, behavior});
    });
  }

  // behavior: ["smooth", "instant", "auto"]
  scrollToHeight(top, behavior = "smooth") {
    angular.element(() => {
      window.scrollTo({top, behavior});
    });
  }

  scrollToElement(id, behavior, headerOffset = -64) {
    angular.element(() => {
      const elementTop = this.getElementTop(id);
      const top = elementTop + window.scrollY + headerOffset;
      this.scrollToHeight(top, behavior);
    });
  }
}
