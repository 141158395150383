/* eslint-disable max-classes-per-file */

class PubsubMessage {
  constructor(message, $injector) {
    Object.assign(this, message);
    this._InboxItemService = $injector.get("InboxItemService");

    this._SUPPORTED_MESSAGE_TYPES = ["Updated", "Created", "Deleted"];
    this._SUPPORTED_WIRELESS_HOLTER_TYPES = ["Unconfigured Device On Patient", "Summary"];
    this._UNSUPPORTED_RHYTHMS = ["Unreadable ECG Data"];
  }

  get isInboxItemType() {
    return this._InboxItemService.INBOX_ITEM_TYPES.includes(this.type);
  }

  get isStudy() {
    return this.type === "study";
  }

  get isEnrollment() {
    return this.type === "enrollment";
  }

  get isSupported() {
    if (!this.isMessageTypeSupported) {
      return false;
    }

    if (this.isInboxItemType && this.isFromWirelessHolterStudy && !this.isSupportedWirelessHolterType) {
      return false;
    }

    if (this.isInboxItemType && this.isUnsupportedRhythm) {
      return false;
    }

    return true;
  }

  get isMessageTypeSupported() {
    return this._SUPPORTED_MESSAGE_TYPES.includes(this.messageType);
  }

  get isFromWirelessHolterStudy() {
    return ["wirelessHolter", "wirelessExtendedHolter"].includes(this.data.studyType);
  }

  get isSupportedWirelessHolterType() {
    return this._SUPPORTED_WIRELESS_HOLTER_TYPES.includes(this.data.type);
  }

  get isUnsupportedRhythm() {
    return this.type === "rhythmChange" && this._UNSUPPORTED_RHYTHMS.includes(this.data.type);
  }
}

/* @ngInject */
export default class PubsubMessageService {
  constructor($injector) {
    this._$injector = $injector;
  }

  instantiate(message) {
    return new PubsubMessage(message, this._$injector);
  }
}
