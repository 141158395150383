/* @ngInject */
export default class Session {
  constructor($injector, Config, $window) {
    this._$injector = $injector;
    this._$window = $window;
    this._sessionStorage = $window.sessionStorage;
    this.features = Config.features;
    this._sessionId = this._sessionStorage.getItem("sessionId") || "";
    if (!this._sessionId) {
      this.generateNewSessionId();
    }
  }

  generateNewSessionId() {
    const sessionId = Math.floor(Math.random() * 1000000000).toString();
    this._sessionStorage.setItem("sessionId", sessionId);
    this._sessionId = sessionId;
    return sessionId;
  }

  trackCurrentPage(page) {
    if (!page) {
      page = this._$window.location.pathname;
    }
    const previousPage = this._sessionStorage.getItem("currentPage");
    this._sessionStorage.setItem("previousPage", previousPage);
    this._sessionStorage.setItem("currentPage", page);
  }

  pageWasRefreshed() {
    return this._sessionStorage.getItem("previousPage") === this._sessionStorage.getItem("currentPage");
  }

  get sessionId() {
    return this._sessionId;
  }

  get(key) {
    return this._sessionStorage.getItem(key);
  }

  getAll() {
    return this._sessionStorage;
  }

  put(key, value) {
    if (key === "sessionId") {
      this._sessionId = value || "";
    }
    return this._sessionStorage.setItem(key, value);
  }

  remove(key) {
    if (key === "sessionId") {
      this._sessionId = "";
    }
    return this._sessionStorage.removeItem(key);
  }

  clear() {
    this._sessionId = "";
    return this._sessionStorage.clear();
  }
}
