import {io} from "socket.io-client";

/* @ngInject */
export default class SocketService {
  constructor(socketFactory, Config, Authentication) {
    this._socketFactory = socketFactory;
    this._Config = Config;
    this._Authentication = Authentication;

    this._connected = false;
    this._listeners = {
      connect: [],
      disconnect: [],
    };

    this.init();
  }

  init() {
    if (!this._socket || !this.connected) {
      const token = this._Authentication.getJwt();

      if (token) {
        const ioSocket = io(this._Config.socketUrl, {
          query: {token},
          transports: ["websocket"],
        });

        this._socket = this._socketFactory({ioSocket});
      } else {
        this._socket = this._socketFactory();
      }

      // Setup listener wrappers for connect and disconnect so that duplicate events don't get processed
      this._socket.on("connect", (...listenerArgs) => {
        this._connected = true;
        return _serializePromiseChain((listener) => listener(...listenerArgs), this._listeners.connect);
      });
      this._socket.on("disconnect", (...listenerArgs) => {
        this._connected = false;
        return _serializePromiseChain((listener) => listener(...listenerArgs), this._listeners.disconnect);
      });
    }

    this.connect();

    return this._socket;
  }

  get socket() {
    return this._socket;
  }

  get connected() {
    return this._connected;
  }

  connect() {
    if (!this._connected) {
      this._socket.connect();
    }
  }

  disconnect() {
    if (this._connected) {
      this._socket.disconnect();
    }
  }

  onConnect(listener) {
    if (typeof listener === "function") {
      this._listeners.connect.push(listener);
    }
  }

  onDisconnect(listener) {
    if (typeof listener === "function") {
      this._listeners.disconnect.push(listener);
    }
  }

  removeConnectionListeners() {
    this._listeners = {
      connect: [],
      disconnect: [],
    };
  }
}

function _serializePromiseChain(functionToExecute, listOfArguments) {
  return listOfArguments.reduce((promiseChain, currentTask) => {
    let chainedResults;
    return promiseChain
      .then((results) => {
        chainedResults = results;
        return functionToExecute(currentTask);
      })
      .then((currentResult) => [...chainedResults, currentResult]);
  }, Promise.resolve([]));
}
