import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";

function FloatingActionBuffer({
  // Props
  "data-cy": dataCy,
}) {
  return (
    <Box
      data-cy={dataCy}
      sx={{
        width: "100%",
        height: "70px",
      }}
    />
  );
}

FloatingActionBuffer.propTypes = {
  "data-cy": PropTypes.string,
};

export default React.memo(FloatingActionBuffer);
