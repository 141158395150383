/* @ngInject */
export default class SocketDisconnect {
  constructor($mdToast) {
    this._$mdToast = $mdToast;
  }

  /**
   * @namespace SocketDisconnect
   *
   * @desc Provides socket disconnect notification
   * @param {object} $mdToast
   * @returns {Promise} socket connection notification instance
   * @memberOf Factories
   */
  showNotification() {
    return this._$mdToast.show(
      this._$mdToast
        .simple()
        .hideDelay(0)
        .position("bottom left")
        .textContent("Your inbox is no longer updating.")
        .toastClass("SocketDisconnectWarning")
        .action("Refresh Page")
    );
  }

  /**
   * Hides the notification and rejects the _$mdToast.show() promise
   * @namespace SocketDisconnect
   *
   * @desc Cancels socket disconnect notification
   * @param {object} $mdToast
   * @returns {Promise} The instance of the hiding of the show notification promise
   * @memberOf Factories
   */
  hideNotification() {
    return this._$mdToast.cancel();
  }
}
