import React from "react";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";

function TableLoading() {
  return (
    <Grid container>
      <Grid sx={{mt: 10}} size={12} align="center">
        <CircularProgress color="secondary" size={100} data-cy="loading-circle" />
      </Grid>
    </Grid>
  );
}

export default TableLoading;
