/* eslint-env browser */
import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";

function IconWithText({
  // Props
  icon,
  text,
  style,
}) {
  //---------------------------------------------------------------------------
  // Rendering
  //---------------------------------------------------------------------------
  return (
    <Box sx={{...style, display: "flex", alignItems: "center"}}>
      {icon}
      <Box sx={{ml: 1}}>{text}</Box>
    </Box>
  );
}

IconWithText.propTypes = {
  icon: PropTypes.node.isRequired,
  text: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.node]).isRequired,
  style: PropTypes.object,
};

export default React.memo(IconWithText);
