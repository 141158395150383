/* eslint-env browser */

/* @ngInject */
export default class ItemAssignmentService {
  constructor(backendConfig, $http, Authentication) {
    this._backendConfig = backendConfig;
    this._$http = $http;
    this._Authentication = Authentication;
  }

  assignItemToUsers(itemId, userIds, facilityId) {
    if (userIds.length === 0) {
      return Promise.resolve();
    }

    const url = `/itemAssignments/${itemId}`;
    const body = {
      facilityId,
      itemId,
      users: userIds.map(this._convertUserIdToObject),
    };

    return this._httpPost(url, body);
  }

  clearAssignments(itemId, userIds, facilityId) {
    if (userIds.length === 0) {
      return Promise.resolve();
    }

    const url = `/itemAssignments/${itemId}`;
    const body = {
      facilityId,
      users: userIds,
    };
    return this._httpDelete(url, body);
  }

  _convertUserIdToObject(userId) {
    return {userId};
  }

  _httpPost(url, body) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.post(`${baseUrl}${url}`, body, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  _httpGet(url, params) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
      params,
    });
  }

  _httpDelete(url, body) {
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http({
      method: "DELETE",
      url: `${baseUrl}${url}`,
      headers: {
        Authorization: authHeader,
      },
      data: body,
    });
  }
}
