/* eslint-disable import/prefer-default-export */

import React from "react";
import {createRoot} from "react-dom/client";

export function angularizeDirective(Component, directiveName, angularApp, bindings) {
  bindings = bindings || {};
  if (typeof window === "undefined" || typeof angularApp === "undefined") {
    return;
  }

  angularApp.directive(directiveName, () => {
    return {
      scope: bindings,
      replace: true,
      link(scope, element) {
        // Add $scope
        scope.$scope = scope;

        // First render
        const root = createRoot(element[0], {identifierPrefix: directiveName});
        root.render(React.createElement(Component, scope));

        // Watch for any changes in bindings, then rerender
        const keys = Object.keys(bindings).filter((bindingKey) => {
          if (/^data[A-Z]/.test(bindingKey)) {
            console.warn(
              `'${bindingKey}' binding for ${directiveName} directive will be undefined because AngularJS ignores attributes starting with data-`
            );
          }
          if (bindings[bindingKey] !== "&") {
            return true;
          }
          return false;
        });

        scope.$watchGroup(keys, () => {
          root.render(React.createElement(Component, scope));
        });

        // Clean up when done
        element.on("$destroy", () => {
          root.unmount();
        });
      },
    };
  });
}
