import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Button from "@mui/material/Button";

function ButtonWithLink({
  // Props
  color,
  variant,
  "data-cy": dataCy,
  link,
  target = "_self",
  children,
}) {
  return (
    <Button color={color} variant={variant} data-cy={dataCy} href={link} target={target}>
      {children}
    </Button>
  );
}

ButtonWithLink.propTypes = {
  color: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  "data-cy": PropTypes.string,
  link: PropTypes.string.isRequired,
  target: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ButtonWithLink;
