import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import CardContent from "@mui/material/CardContent";
import styled from "@mui/system/styled";

const StyledCardContent = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

function CardContentNoPadding({className, spacing, id, "data-cy": dataCy, children}) {
  return (
    <StyledCardContent className={className} spacing={spacing} id={id} data-cy={dataCy}>
      {children}
    </StyledCardContent>
  );
}

CardContentNoPadding.propTypes = {
  className: PropTypes.string,
  spacing: PropTypes.number,
  id: PropTypes.string,
  "data-cy": PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default CardContentNoPadding;
