/* @ngInject */
export default class LoadingCircleService {
  constructor() {
    this._counterValue = 0;
  }

  get counterValue() {
    return this._counterValue;
  }

  set counterValue(value) {
    this._counterValue = value;
  }

  incrementCounter() {
    this._counterValue++;
  }

  decrementCounter() {
    this._counterValue--;
  }
}
