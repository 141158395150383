/* eslint-env browser */
/* eslint-disable max-classes-per-file */

import {DateTime} from "luxon";
import queryString from "qs";

class Study {
  constructor(study) {
    study.studyId = study.id;
    try {
      study.studyNotes = JSON.parse(study.studyNotes);
    } catch (error) {
      // do nothing
    }

    // Valid timestamps are 2010 or later
    study.validStudyStartDate = DateTime.fromISO(study.studyStartDate).year >= 2010;

    if (study.dataReceived && study.validStudyStartDate) {
      study.displayedStudyStartDate = DateTime.fromJSDate(new Date(study.studyStartDate)).toFormat(
        "yyyy-MM-dd HH:mm"
      );
    } else if (study.dataReceived && !study.validStudyStartDate) {
      study.displayedStudyStartDate = "Unknown Timestamp";
    } else if (!study.dataReceived) {
      study.displayedStudyStartDate = "Pending Data Upload";
    }

    study.artifactRegions = [];

    Object.assign(this, study);
  }
}

/* @ngInject */
export default class StudyService {
  constructor($injector, backendConfig, $http) {
    this._$injector = $injector;
    this._backendConfig = backendConfig;
    this._features = this._backendConfig.features;
    this._$http = $http;
    this._EnrollmentService = $injector.get("EnrollmentService");
    this._Authentication = $injector.get("Authentication");

    this.displayedStudyTypes = {
      holter: "Holter",
      extendedHolter: "Extended Holter",
      wirelessHolter: "Wireless Holter",
      wirelessExtendedHolter: "Wireless Extended Holter",
      mct: "Mobile Cardiac Telemetry (MCT)",
      cem: "Cardiac Event Monitoring (CEM)",
      mctWithFullDisclosure: "MCT with Full Disclosure",
      cardiacRehab: "Cardiac Rehab",
    };
    if (!this._features.extendedHolterStudyType) {
      delete this.displayedStudyTypes.extendedHolter;
      delete this.displayedStudyTypes.wirelessExtendedHolter;
    }
  }

  /**
   * @param {string} studyId
   * @param {Object} params - properties to limit getStudies request by
   * @returns {Promise<Object[]>} studies
   */
  async getStudyAndAttachEnrollments(studyId, params = {}) {
    const study = (await this._httpGet(`/studies/${studyId}`, params)).data;
    const enrollments = await this._EnrollmentService.getEnrollments({studyId: study.id});

    this._attachAdditionalStudyProperties(study, enrollments);

    return new Study(study, this._features);
  }

  /**
   * @param {Object} studyId
   * @param {Object} propertiesToUpdate
   * @returns {Promise} study patch request
   * @see SRS: BR-1027
   */
  updateStudy(studyId, propertiesToUpdate) {
    const url = `/studies/${studyId}`;
    return this._httpPatch(url, propertiesToUpdate).then((response) => response.data);
  }

  /**
   * @param {Object} studyId
   * @returns {Promise} study post request
   */
  finalizeStudy(studyId) {
    const url = `/studies/${studyId}/finalize`;
    return this._httpPost(url).then((response) => response.data);
  }

  /**
   * @param {String} studyType - study type property
   * @returns {String} string of displayed study type
   *
   * @see SRS: BR-20
   */
  getDisplayedStudyType(studyType) {
    return this.displayedStudyTypes[studyType];
  }

  _attachAdditionalStudyProperties(study, enrollments) {
    study.studyIsPending = enrollments.every((e) => !e.deviceEnrollmentId) && study.studyType !== "holter";
    study.allEnrollments = enrollments;

    // Sort enrollments so that the newest one is at the top of the array
    study.allEnrollments.sort((e1, e2) => {
      const startTime1 = new Date(e1.enrollmentStartTime);
      const startTime2 = new Date(e2.enrollmentStartTime);
      return startTime2 - startTime1;
    });
  }

  /**
   * @param {string} url   - the url to make a request to
   * @param {Object} params - the query parameters to attach to the request
   * @returns {Promise} get request
   * @see SRS: BR-12
   */
  _httpGet(url, params) {
    const urlQuery = queryString.stringify(params);
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}?${urlQuery}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }

  _httpPost(url, data) {
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.post(`${baseUrl}${url}`, data);
  }

  _httpPatch(url, data) {
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.patch(`${baseUrl}${url}`, data);
  }
}
