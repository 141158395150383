import React from "react";
import {Controller, useFormContext} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Chip from "@mui/material/Chip";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

function FormStringInput({
  // Props
  control,
  name,
  label,
  id,
  "data-cy": dataCy,
  type,
  htmlInputProps,
  inputProps,
  defaultValue,
  required = true,
  disabled = false,
  options,
  rules,
  size,
  otherProps,
}) {
  const context = useFormContext();

  //---------------------------------------------------------------------------
  // Improve HTML number inputs
  //---------------------------------------------------------------------------
  const blockedKeys = React.useMemo(() => {
    if (type === "number") {
      const blockMinus = typeof htmlInputProps?.min === "number" && htmlInputProps?.min >= 0;
      const blockDecimal = !!rules?.validate?.integer;
      return ["e", "E", "+", ...(blockMinus ? ["-"] : []), ...(blockDecimal ? ["."] : [])];
    }

    return [];
  }, [htmlInputProps, rules, type]);

  const handleKeyDown = React.useCallback(
    (event) => {
      if (blockedKeys?.includes(event.key)) {
        event.preventDefault();
      }
    },
    [blockedKeys]
  );

  //---------------------------------------------------------------------------
  // Support scroll-wheel editing of number inputs
  //---------------------------------------------------------------------------
  const inputRef = React.useRef();
  React.useEffect(() => {
    const emptyHandler = () => {};
    const currentRef = inputRef.current;
    currentRef?.addEventListener("wheel", emptyHandler);
    return () => currentRef?.removeEventListener("wheel", emptyHandler);
  }, []);

  const render = React.useCallback(
    ({field: {onChange, value}, fieldState: {error}}) => (
      <TextField
        name={name}
        required={required}
        ref={inputRef}
        label={label}
        id={id}
        data-cy={dataCy}
        type={type}
        select={!!options}
        variant="standard"
        disabled={disabled}
        fullWidth
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
        error={!!error}
        helperText={error ? error.message : null}
        slotProps={{
          input: inputProps,
          htmlInput: htmlInputProps,
          formHelperText: {"data-cy": `${dataCy}-helper-text`},
        }}
        {...otherProps}
        size={size}
      >
        {!!options &&
          options.map((option) => (
            <MenuItem
              key={option.id}
              value={option.value ?? option.id}
              disabled={option.disabled === true}
              id={`${id || dataCy}-${option.id}`}
              data-cy={`${dataCy}-${option.id}`}
            >
              <Typography component="span" sx={{marginRight: 1}}>
                {option.name}
              </Typography>
              {option.isDefault && <Chip size="small" label="Default" />}
            </MenuItem>
          ))}
      </TextField>
    ),
    [
      dataCy,
      disabled,
      handleKeyDown,
      htmlInputProps,
      id,
      inputProps,
      label,
      name,
      options,
      otherProps,
      required,
      size,
      type,
    ]
  );

  return (
    <Controller
      name={name}
      control={context?.control || control}
      defaultValue={defaultValue !== undefined ? defaultValue : context?.defaultValues[name]}
      shouldUnregister
      render={render}
      rules={rules}
    />
  );
}

FormStringInput.propTypes = {
  /**
   * The [control](https://react-hook-form.com/api/useform/control)
   * object provided by invoking `useForm`.
   * Optional when using `FormProvider`.
   *
   * @example
   * ```js
   * import { useForm } from 'react-hook-form';
   * const { control, handleSubmit, getValues } = useForm();
   * ```
   * pass in control
   */
  control: PropTypes.object,

  /**
   * Unique name of your input field
   */
  name: PropTypes.string.isRequired,

  /**
   * The label content
   */
  label: PropTypes.string,

  id: PropTypes.string,

  /**
   * The identifier of your input field for Cypress testing
   */
  "data-cy": PropTypes.string,

  /**
   * Type of the `input` element
   */
  type: PropTypes.string,

  /**
   * HTML input props for the input field, this can set the minimum number the scroll can reach,
   * the maximum number the scroll can reach, and the precision the input can have.
   * *** This is important when using type: 'number' ***
   */
  htmlInputProps: PropTypes.object,

  /**
   * Custom input props for the input field.
   */
  inputProps: PropTypes.object,

  /**
   * **Important:** cannot apply undefined to `defaultValue`
   * * You need to either set `defaultValue` at the field-level
   *   or `useForm`'s `defaultValue`s.  Undefined is not a valid value.
   * * If your form will invoke `reset` with default values,
   *   you will need to provide `useForm` with `defaultValues`
   */
  defaultValue: PropTypes.node,

  /**
   * If `true`, the label is displayed as required
   * and the input element is required.
   */
  required: PropTypes.bool,

  /**
   * If `true` the component is disabled
   */
  disabled: PropTypes.bool,

  /**
   * If `true` enables a select menu
   */
  options: PropTypes.array,

  /**
   * Validation rules in the same format for `register`,
   * which includes:
   * required, min, man, minLength, maxLength, pattern, validate
   *
   * @example
   * ```jsx
   * rules={{ required: true }}
   * ```
   */
  rules: PropTypes.object,

  size: PropTypes.string,

  otherProps: PropTypes.object,
};

export default FormStringInput;
