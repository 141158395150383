import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

function ShowMoreText({limit = 10, id, children, "data-cy": dataCy}) {
  const [hidden, setHidden] = React.useState(true);

  const itemsForDisplay = React.useMemo(() => {
    if (children.length > limit && hidden) {
      return children.slice(0, limit);
    }
    return children;
  }, [children, limit, hidden]);

  const handleClick = React.useCallback(() => setHidden((prev) => !prev), []);

  return (
    <>
      {itemsForDisplay}

      {children.length > limit && (
        <Box sx={{display: "flex", justifyContent: "center"}}>
          <Button id={id} data-cy={dataCy} size="small" color="secondary" onClick={handleClick}>
            {hidden ? "Show More" : "Show Less"}
          </Button>
        </Box>
      )}
    </>
  );
}

ShowMoreText.propTypes = {
  limit: PropTypes.number,
  id: PropTypes.string,
  "data-cy": PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.node),
};
export default ShowMoreText;
