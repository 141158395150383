/* @ngInject */
export default class Lock {
  constructor($injector, Config) {
    this._Config = Config;
    this._$injector = $injector;
    this.features = this._Config.features;
    this._lockedItem = null;
    this._lockingItem = false;
    this._lockingEnabled = true;
  }

  get lockedItem() {
    return this._lockedItem;
  }

  get isLockingItem() {
    return this._lockingItem;
  }

  disableLocking() {
    this._lockingEnabled = false;
  }

  enableLocking() {
    this._lockingEnabled = true;
  }

  lockItem(item) {
    // call the item's lock function if we are not already calling lock on another item (lock and unlock function are required)
    if (this._lockingEnabled && !this._lockingItem && item && item.lock && item.unlock && !item.unlocking) {
      this._lockingItem = true;
      item.locking = true;
      return item
        .lock()
        .then((result) => {
          this._lockedItem = item;
          this._lockingItem = false;
          item.locking = false;
          return result;
        })
        .catch((err) => {
          this._lockingItem = false;
          item.locking = false;
          throw err;
        });
    }
    return Promise.resolve({});
  }

  refreshItemLock() {
    if (this._lockingEnabled && this._lockedItem) {
      return this.lockItem(this._lockedItem);
    }
    return Promise.resolve({});
  }

  unlockItem(item) {
    let itemToUnlock = this._lockedItem;
    if (item) {
      itemToUnlock = item;
    }
    if (itemToUnlock) {
      itemToUnlock.unlocking = true;
      return itemToUnlock
        .unlock()
        .then(() => {
          this._lockedItem = null;
          itemToUnlock.unlocking = false;
        })
        .catch((err) => {
          itemToUnlock.unlocking = false;
          throw err;
        });
    }
    return Promise.resolve();
  }

  unlockAllItemsForSession(jwt) {
    const userId = this._$injector.get("Authentication").getUserId(jwt);
    const {sessionId} = this._$injector.get("Session");
    const urlWithQuery = `/inboxItems/lock?userId=${userId}&sessionId=${sessionId}`;

    return this._httpDelete(urlWithQuery, jwt).then((response) => {
      this._lockedItem = null;
      return response;
    });
  }

  unlockAllItemsForUser(jwt) {
    const userId = this._$injector.get("Authentication").getUserId(jwt);
    const urlWithQuery = `/inboxItems/lock?userId=${userId}`;

    return this._httpDelete(urlWithQuery, jwt).then((response) => {
      this._lockedItem = null;
      return response;
    });
  }

  _httpDelete(url, jwt) {
    if (!jwt) {
      jwt = this._$injector.get("Authentication").getJwt();
    }
    const authHeader = `Bearer ${jwt}`;
    const baseUrl = `${this._Config.apiUrl}`;
    return this._$injector.get("$http").delete(`${baseUrl}${url}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }
}
