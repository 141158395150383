import React from "react";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Typography from "@mui/material/Typography";

function TextWithNewlines({text, fontSize = "16px", "data-cy": dataCy, variant}) {
  const lines = text.split(/\r?\n/);

  if (variant) {
    return (
      <div data-cy={dataCy}>
        {lines.map((line) => (
          <Typography key={line} variant={variant}>
            {line}
          </Typography>
        ))}
      </div>
    );
  }

  return (
    <div data-cy={dataCy}>
      {lines.map((line) => (
        <div key={line} style={{fontSize}}>
          {line}
        </div>
      ))}
    </div>
  );
}

TextWithNewlines.propTypes = {
  text: PropTypes.string,
  fontSize: PropTypes.string,
  "data-cy": PropTypes.string,
  variant: PropTypes.string,
};

export default TextWithNewlines;
