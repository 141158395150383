import queryString from "qs";

/* @ngInject */
export default class UserService {
  constructor(backendConfig, $http, Authentication) {
    this._backendConfig = backendConfig;
    this._$http = $http;
    this._Authentication = Authentication;
  }

  getUsers(params = {}) {
    const url = "/users";
    return this._httpGet(url, params).then((response) => {
      return response.data.map((user) => user);
    });
  }

  _httpGet(url, params) {
    const urlQuery = queryString.stringify(params);
    const token = this._Authentication.getJwt();
    const authHeader = `Bearer ${token}`;
    const baseUrl = `${this._backendConfig.apiUrl}`;
    return this._$http.get(`${baseUrl}${url}?${urlQuery}`, {
      headers: {
        Authorization: authHeader,
      },
    });
  }
}
