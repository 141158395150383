import React from "react";
import {DateTime} from "luxon";
import PropTypes from "prop-types";

import FormDateTimeInput from "./FormDateTimeInput.jsx";

function StartDateTimeInput({
  // Props
  control,
  study,
  label,
  variant,
  required = false,
}) {
  return (
    <FormDateTimeInput
      control={control}
      name="studyStartDate"
      id="studyStartDate"
      label={label || ""}
      data-cy="datetime-picker"
      defaultValue={DateTime.fromISO(study.studyStartDate)}
      size="small"
      timeZone={study.timeZone}
      variant={variant || "standard"}
      otherProps={{
        ampm: false,
        timeSteps: {minutes: 1},
        minDateTime: DateTime.fromISO("2010-01-01"),
        maxDateTime: study.studyEndDate ? DateTime.fromISO(study.studyEndDate) : null,
      }}
      rules={{
        ...(required ? {required: "Date and Time is required"} : {}),
        validate: (value) => {
          if (value === null || value.invalid) {
            return "Please enter a valid date";
          }
          if (study.studyEndDate && value > DateTime.fromISO(study.studyEndDate)) {
            return "Date cannot be after the end of the study";
          }
          if (value < DateTime.fromISO("2010-01-01")) {
            return "Date cannot be before the year 2010";
          }
          return true;
        },
      }}
    />
  );
}

StartDateTimeInput.propTypes = {
  control: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
  label: PropTypes.string,
  variant: PropTypes.string,
  required: PropTypes.bool,
};

export default StartDateTimeInput;
