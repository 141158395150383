/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import {useDropzone} from "react-dropzone";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Icons
//---------------------------------------------------------------------------
import Clear from "@mui/icons-material/Clear";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import UploadFile from "@mui/icons-material/UploadFile";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import {useTheme} from "@mui/material/styles";

//---------------------------------------------------------------------------
// BitRhythm Components
//---------------------------------------------------------------------------
import IconButtonWithTooltip from "./IconButtonWithTooltip.jsx";

function FormFileInput({
  // Props
  control,
  name,
  label,
  id,
  defaultValue,
  rules,
  accept,
  preview = false,
}) {
  const theme = useTheme();

  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border .24s ease-in-out",
  };
  const activeStyle = {
    borderColor: "#2196f3",
  };
  const acceptStyle = {
    borderColor: theme.palette.secondary.main,
  };
  const rejectStyle = {
    borderColor: "#ff1744",
  };
  const validator = (file) => {
    if (rules?.pattern) {
      const pattern = rules.pattern?.value || rules.pattern;
      const errorMessage = rules.pattern?.message || "";
      if (!pattern.test(file.name)) {
        return {
          code: "incorrect-suffix",
          message: errorMessage,
        };
      }
    }

    const maxSize = Number(rules?.maxSize?.value || rules?.maxSize) || 0; // Max size must be in bytes
    if (maxSize) {
      const errorMessage = rules.maxSize?.message || "";
      if (file.size > maxSize) {
        return {
          code: "file-too-large",
          message: errorMessage,
        };
      }
    }
    return null;
  };

  const [filePreview, setFilePreview] = React.useState();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister
      rules={{
        required: !!rules.required?.value,
      }}
      render={({field: {onChange, value}, fieldState: {error}}) => {
        const onDrop = React.useCallback(
          (acceptedFiles) => {
            if (acceptedFiles.length > 0) {
              onChange(acceptedFiles[0]);
              if (preview) {
                setFilePreview(URL.createObjectURL(acceptedFiles[0]));
              }
            } else {
              onChange(undefined);
            }
          },
          [onChange]
        );
        const {fileRejections, getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject} =
          useDropzone({
            maxFiles: 1,
            validator,
            onDrop,
            accept,
          });
        const style = React.useMemo(
          () => ({
            ...baseStyle,
            ...(isDragActive ? activeStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
          }),
          [isDragActive, isDragReject, isDragAccept]
        );
        const clear = () => {
          onChange(undefined);
        };

        return (
          <>
            <Grid size={{xs: 12, sm: preview ? 12 : 6}} sx={{px: 0}}>
              <div {...getRootProps({style})}>
                <input {...getInputProps({onChange})} id={id} />
                <Box style={{display: "flex", alignItems: "center"}} sx={{px: 2}}>
                  <Box sx={{px: 2}}>{label}</Box>
                  <Divider flexItem orientation="vertical" variant="middle" />
                  <Box sx={{px: 2}}>
                    <Button color="secondary" variant="contained" data-cy="browse-button">
                      Browse
                    </Button>
                  </Box>
                </Box>
              </div>
            </Grid>
            <Grid size={{xs: 12, sm: preview ? 12 : 6}} data-cy="file-input-message" sx={{px: 0}}>
              {value?.name && (
                <p style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                  <UploadFile color="secondary" />
                  &nbsp;{value.name}
                  <IconButtonWithTooltip
                    title="Remove File"
                    color="secondary"
                    id="remove-file-button"
                    onClick={clear}
                  >
                    <Clear />
                  </IconButtonWithTooltip>
                </p>
              )}
              {!value?.name && rules.required?.value && (
                <p style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                  <ErrorOutline color="error" />
                  &nbsp;{rules.required.message}
                </p>
              )}
              {fileRejections.length > 0 && (
                <p style={{display: "flex", alignItems: "center", flexWrap: "wrap"}}>
                  <ErrorOutline color="error" />
                  &nbsp;{fileRejections[0].errors[0].message}
                </p>
              )}
            </Grid>
            {preview && value?.name && (
              <Grid>
                <embed
                  data-cy="document-preview"
                  src={`${filePreview}#toolbar=0`}
                  height="400"
                  width="100%"
                />
              </Grid>
            )}
          </>
        );
      }}
    />
  );
}

FormFileInput.propTypes = {
  /**
   * The [control](https://react-hook-form.com/api/useform/control)
   * object provided by invoking `useForm`.
   * Optional when using `FormProvider`.
   *
   * @example
   * ```js
   * import { useForm } from 'react-hook-form';
   * const { control, handleSubmit, getValues } = useForm();
   * ```
   * pass in control
   */
  control: PropTypes.object.isRequired,

  /**
   * Unique name of your input field
   */
  name: PropTypes.string.isRequired,

  /**
   * The label content
   */
  label: PropTypes.string.isRequired,

  /**
   * The id of your input field
   */
  id: PropTypes.string,

  /**
   * The default value of the input field
   */
  defaultValue: PropTypes.object,

  /**
   * Validation rules in the same format for `register`,
   * which includes:
   * required, min, max, minLength, maxLength, pattern, validate
   *
   * @example
   * ```jsx
   * rules={{ required: true }}
   * ```
   */
  rules: PropTypes.object,

  /**
   * MIME type and file extension rules
   * See also:
   *    https://react-dropzone.org/#!/Accepting%20specific%20file%20types
   *    https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
   *
   * @example
   * ```jsx
   * accept={{"application/pdf": [".pdf"]}}
   * ```
   */
  accept: PropTypes.object,

  /**
   * A boolean that toggles a file preview
   */
  preview: PropTypes.bool,
};

export default FormFileInput;
