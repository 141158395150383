import React from "react";
import {Controller} from "react-hook-form";
import PropTypes from "prop-types";

//---------------------------------------------------------------------------
// MUI Components
//---------------------------------------------------------------------------
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import styled from "@mui/system/styled";

const StyledSwitch = styled(Switch)(({theme}) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    borderRadius: 22 / 2,
    "&:before, &:after": {
      content: '""',
      position: "absolute",
      top: "50%",
      transform: "translateY(-50%)",
      width: 16,
      height: 16,
    },
    "&:before": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    "&:after": {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));

function SwitchInput({
  // Props
  control,
  name,
  label,
  "data-cy": dataCy,
  disabled = false,
  required = false,
  defaultValue = false,
  color,
  labelPlacement = "end",
  otherProps,
}) {
  if (label) {
    return (
      <Controller
        name={name}
        control={control}
        shouldUnregister
        defaultValue={defaultValue}
        render={({field: {onChange, value}}) => {
          return (
            <FormGroup name={name} value={value} onChange={onChange}>
              <FormControlLabel
                label={label}
                disabled={disabled}
                labelPlacement={labelPlacement}
                control={<StyledSwitch color={color} checked={value} data-cy={dataCy} />}
              />
            </FormGroup>
          );
        }}
      />
    );
  }

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      shouldUnregister
      render={({field: {onChange, value}}) => (
        <StyledSwitch
          checked={value}
          onChange={onChange}
          disabled={disabled}
          required={required}
          data-cy={dataCy}
          color={color}
          {...otherProps}
        />
      )}
    />
  );
}

SwitchInput.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  "data-cy": PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  defaultValue: PropTypes.bool,
  color: PropTypes.string.isRequired,
  labelPlacement: PropTypes.string,
  otherProps: PropTypes.object,
};

export default SwitchInput;
