/* @ngInject */
export default function interceptorsFactory(Authentication, Config) {
  let apiUrl;

  if (Config) {
    apiUrl = Config.apiUrl;
  }

  const Interceptors = {
    /**
     * Append Authorization header to every outgoing request
     * if Access-Token exists
     * @param  {object} config $http config object
     * @return {object}        $http config object
     */
    request: function requestInterceptor(config) {
      if (config.url.startsWith(apiUrl)) {
        const jwt = Authentication.getJwt();

        if (jwt !== undefined) {
          config.headers.Authorization = `Bearer ${jwt}`;
        }
      }

      return config;
    },
  };

  return Interceptors;
}
